import React, { useState, useContext, useEffect } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Filter, Close } from "../components/icons"

function Blog({ location, data }) {
  const state = useContext(GlobalStateContext)
  const { tags, noResults, filter } = state.language
  const siteTitle = data.site.siteMetadata.title
  // const allTags = data.allMdx.edges.node.frontmatter.tags
  const posts = data.allMdx.edges
  const [results, setResults] = useState(posts)
  const [selectedTags, setSelectedTags] = useState([])

  useEffect(() => {
    const filteredResults = selectedTags.map(tag =>
      posts.filter(
        item =>
          item.node.frontmatter.tags && item.node.frontmatter.tags.includes(tag)
      )
    )
    selectedTags.length !== 0
      ? setResults(_getUnique(filteredResults.flat()))
      : setResults(posts)
  }, [selectedTags, posts])

  const _getUnique = arr => [
    ...new Map(arr.map(item => [item.node.fields.slug, item])).values(),
  ]

  function _filterResults(e, tagName) {
    e.currentTarget.parentNode.classList.add("active")
    selectedTags.length === 0
      ? setSelectedTags([tagName])
      : setSelectedTags([...selectedTags, tagName])
  }

  function _removeTag(tagName) {
    setSelectedTags(selectedTags.filter(item => item !== tagName))
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Portfolio" />
      <section className="section-portfolio">
        <div className="wrapper">
          <details>
            <summary data-grid="row small-spacing">
              <div data-cell="shrink">
                <Filter width="30px" height="30px" />
              </div>
              <b data-cell="shrink">{filter}</b>
            </summary>
            <div data-grid="wrap spacing" className="tags-area">
              {tags.map((item, index) => (
                <p key={index} data-grid="center row" data-cell="shrink">
                  <a
                    data-cell="nospace shrink"
                    onClick={e => _filterResults(e, item)}
                  >
                    {item}
                  </a>
                  <a
                    data-cell="nospace shrink"
                    onClick={e => {
                      e.currentTarget.parentNode.classList.remove("active")
                      _removeTag(item)
                    }}
                  >
                    <Close width="12px" height="12px" />
                  </a>
                </p>
              ))}
            </div>
          </details>
          <div style={{ margin: "20px 0 40px" }} data-grid="spacing cols-3">
            {results.length === 0 && <h2>{noResults}</h2>}
            {results.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              const englishTitle = node.frontmatter.englishTitle
              const {
                frontmatter: {
                  tags,
                  featuredImage,
                  company,
                  linkCompany,
                },
              } = node
              return (
                <div
                  data-cell="1of3 shrink"
                  key={node.fields.slug}
                  className="card-img"
                >
                  {node.frontmatter.featuredImage && (
                    <div className="cover-img">
                      <Img sizes={featuredImage.childImageSharp.sizes} />
                    </div>
                  )}
                  <section className="text-content" data-grid="center">
                    <div data-cell="fill">
                      <small>
                        Developed in{" "}
                        <a
                          href={linkCompany}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {company}
                        </a>
                      </small>
                      <b>
                        <Link
                          style={{ boxShadow: `none` }}
                          to={`/portfolio${node.fields.slug}`}
                        >
                          {state.language.current === "english"
                            ? englishTitle
                            : title}
                        </Link>
                      </b>
                      <p data-cell="end" style={{ whiteSpace: "nowrap" }}>
                        {tags &&
                          tags.map(item => <span className="tag">{item}</span>)}
                      </p>
                    </div>
                  </section>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            englishTitle
            description
            englishDescription
            linkCompany
            linkProject
            company
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            englishBody
            tags
          }
        }
      }
    }
  }
`
